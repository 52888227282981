<template>
  <main class="home-page">
    <h1>👋</h1>
    <div class="projects">
      <div class="projects__list">
        <a
          :href="route({ name: 'board', params: { id: board.id } })"
          class="projects__item"
          v-for="(board, i) in boards"
          :key="i"
        >
          {{ board.name }}
        </a>
      </div>
    </div>
  </main>
</template>

<script>
import { getBoards, route } from "@/utils.js";

export default {
  name: "HomePage",
  methods: { route },
  async asyncData(app) {
    await getBoards(app);
  },
  computed: {
    boards() {
      return this.$store.state.boards;
    },
  },
};
</script>

<style lang="stylus">
.home-page {
  padding 20px 24px
}

.projects {
  display grid
  grid-gap 10px

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 24px
  }

  &__item {
    background var(--gray)
    padding 24px 12px
    text-decoration none
    font-size 1em
    line-height 20px
    font-weight 500
    color var(--dark)
    border: 1px solid var(--gray-100);
    box-sizing: border-box;
    border-radius: var(--radius);
  }
}
</style>
